/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from 'react';
// import { Message } from '@api/types/chats';
// import Cookies from 'js-cookie';
import { io } from 'socket.io-client';
import environment from '../../environment/environment';
import { TravelTaked } from 'app/viaje/services/TravelsCordinate';

export interface INotificationsCoordinationContext {
  driversRequests: any[];
  travelsRejected: any[];
  travelsTaked: TravelTaked[];
  setTravelsTaked: React.Dispatch<React.SetStateAction<TravelTaked[]>>;
  setDriversRequests: React.Dispatch<React.SetStateAction<any[]>>;
  setTravelsRejected: React.Dispatch<React.SetStateAction<any[]>>;
  getTravelsTaked: () => Promise<void>;
}

enum Events {
  DRIVER_CLOSE_SHIFT = 'driver_close_shift',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  DRIVER_APPROVED_SHIFT = 'driver_approved_shift',
  RESERVATION_REJECTED_BY_DRIVER = 'reservation_rejected_by_driver',
  RESERVATION_TAKES = 'transfer_reservations_take_untake'
}

export const NotificationsCoordinationContext =
  createContext<INotificationsCoordinationContext>({
    driversRequests: [],
    travelsTaked: [],
    setTravelsTaked: () => {},
    setDriversRequests: () => {},
    travelsRejected: [],
    setTravelsRejected: () => {},
    getTravelsTaked: async () => {}
  });

const socket = io(environment.notifications, {
  autoConnect: false,
  transports: ['websocket', 'polling']
});

export const NotificationsProvider = ({ children }) => {
  const [driversRequests, setDriversRequests] = useState<any[]>([]);
  const [travelsRejected, setTravelsRejected] = useState<any[]>([]);
  const [travelsTaked, setTravelsTaked] = useState<TravelTaked[]>([]);

  useEffect(() => {
    // no-op if the socket is already connected
    const onDriverCloseShift = (data: any) => {
      setDriversRequests((prev) => [...prev, data]);
    };

    const onDriverApprovedShift = (data: any) => {
      setDriversRequests((prev) => prev.filter((req) => req.id !== data.id));
    };

    const onReservationRejectedByDriver = (data: any) => {
      setTravelsRejected((prev) => [...prev, data]);
    };

    const onTakeTravelByUser = (data: any) => {
      if (data.action === 'UN_TAKE') {
        setTravelsTaked((prev) =>
          prev.filter(
            (travel) =>
              travel.transfer_reservation_id !== data.transfer_reservation_id
          )
        );
      } else {
        setTravelsTaked((prev) => [
          ...prev.filter((travel) => travel.user_id !== data.user_id),
          // ...travelsTaked,
          data
        ]);
      }
    };

    socket.open();
    socket.on(Events.DRIVER_CLOSE_SHIFT, onDriverCloseShift);
    socket.on(Events.DRIVER_APPROVED_SHIFT, onDriverApprovedShift);
    socket.on(
      Events.RESERVATION_REJECTED_BY_DRIVER,
      onReservationRejectedByDriver
    );
    socket.on(Events.RESERVATION_TAKES, onTakeTravelByUser);
    socket.on(Events.DISCONNECT, () => {
      socket.removeAllListeners();
      socket.disconnect();
      console.log('Socket disconnected!');
    });

    return () => {
      socket.off(Events.DRIVER_CLOSE_SHIFT);
      socket.off(Events.DRIVER_APPROVED_SHIFT);
      socket.off(Events.RESERVATION_REJECTED_BY_DRIVER);
      socket.off(Events.RESERVATION_TAKES);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversRequests]);

  const getTravelsTaked = async () => {
    const response = await fetch(
      `${environment.matching}/transfer-reservations-takes`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-Api-Key': environment.matchingKeys
        }
      }
    );
    const result = await response.json();
    setTravelsTaked(result);
  };

  return (
    <NotificationsCoordinationContext.Provider
      value={{
        driversRequests,
        travelsTaked,
        setTravelsTaked,
        setDriversRequests,
        travelsRejected,
        setTravelsRejected,
        getTravelsTaked
      }}
    >
      {children}
    </NotificationsCoordinationContext.Provider>
  );
};

export const useNotificationsContext = () => {
  return useContext(NotificationsCoordinationContext);
};
