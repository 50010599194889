import { useState } from 'react';
import { MonitorElemItem } from '../../../../../app/chofer/components/DriversMonitor/MonitorElem';
import { MonitorGroup } from '../../../../../app/chofer/components/DriversMonitor/MonitorGroup';
import { ChoferTrabajando } from 'app/chofer/models/ChoferTrabajando';
import { Button } from '@vadiun/react-components';
import { tw } from 'utils/tailwindUtils';

interface Props {
  workingDrivers: ChoferTrabajando[];
  driversWorkingDayNotStarted: ChoferTrabajando[];
  groupType: string;
  showDriverDetails: (driver: ChoferTrabajando) => void;
  isActive: boolean;
  showDriversSleeping: boolean;
  statusActive: string;
}

export function DriversMonitor({
  workingDrivers,
  statusActive,
  driversWorkingDayNotStarted,
  showDriverDetails,
  isActive,
  showDriversSleeping
}: Props) {
  const groupBy = function (xs, key): { [key: string]: ChoferTrabajando[] } {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const groupedBy = (
    groupType,
    drivers: ChoferTrabajando[]
  ): {
    groups: MonitorElemItem[];
    sinTurnoAbiertoGroups: MonitorElemItem[];
  } => {
    const groupedDrivers = groupBy(drivers, groupType);
    let sinTurnoAbiertoGroups: MonitorElemItem[] = [];

    let entries: MonitorElemItem[] = Object.entries(groupedDrivers).map(
      ([key, drivers]) => ({
        name: key,
        workingDrivers: drivers
      })
    );
    // if (driversOnBreak.length > 0 && showDriverUnavailable) {
    //   entries.push({ name: 'No disponible', workingDrivers: driversOnBreak });
    // }
    if (driversWorkingDayNotStarted.length > 0 && showDriversSleeping) {
      sinTurnoAbiertoGroups = Object.entries(
        groupBy(
          driversWorkingDayNotStarted.sort((a, b) => a.code - b.code),
          groupType
        )
      ).map(([key, drivers]) => ({
        name: key,
        workingDrivers: drivers
      }));
    }
    return { groups: entries, sinTurnoAbiertoGroups };
  };

  const orderByMoneyPerHourEarned = (drivers: ChoferTrabajando[]) => {
    return drivers.sort((a, b) =>
      a.income_since_work_day_started > b.income_since_work_day_started ? -1 : 1
    );
  };

  const [district, setDistrict] = useState<string | undefined>(undefined);
  let showGrouped: MonitorElemItem[] = [];
  let sinTurnoAbiertoGroups: MonitorElemItem[] = [];

  if (!district) {
    const data = groupedBy(
      'current_district',
      orderByMoneyPerHourEarned(workingDrivers)
    );

    showGrouped = data.groups;
    sinTurnoAbiertoGroups = data.sinTurnoAbiertoGroups;
  } else {
    const districtDrivers = workingDrivers.filter(
      (d) => d.current_district === district
    );
    const data = groupedBy(
      'current_locality',
      orderByMoneyPerHourEarned(districtDrivers)
    );
    showGrouped = data.groups;
    sinTurnoAbiertoGroups = data.sinTurnoAbiertoGroups;
  }

  const showDistrictLocalities = (districtName: string) => {
    setDistrict(districtName);
  };

  return (
    <div
      className={tw(
        'w-full hidden flex-col bg-white rounded-xl px-4 py-5 mt-2 duration-300',
        {
          flex: isActive
        }
      )}
    >
      <div className="flex justify-between p-0">
        <MonitorGroup
          isActive={isActive}
          groups={showGrouped}
          showDistrictLocalities={showDistrictLocalities}
          showDriverDetails={showDriverDetails}
          statusActive={statusActive}
        />
        {district && (
          <div className="flex items-center">
            <Button variant="light" onClick={() => setDistrict(undefined)}>
              Partidos
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
